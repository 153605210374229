@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.bodyLogin {
    background-color: #c9d6ff;
    background: linear-gradient(rgba(0,
                    147,
                    251, 0.4), rgba(0,
                    147,
                    251, 0.3)), url('../../Assets/hero.png');
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-size: cover;
}

.container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    width: 50vw !important;
}

.container p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 5px 0;
}

.container span {
    font-size: 12px;
}

.container a {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 5px 0 10px;
}

.container button {
    background-color: #0093fb;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.container button.hidden {
    background-color: transparent;
    border-color: #fff;
}

.container form {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}
/*
.container input {
    border: none;
    margin: 0px 0;
    padding: 10px 15px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}
 */
.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in {
    left: 0;
    width: 50%;

}
.sign-up-spacing{
    display: flex;
    justify-content: space-between;
}
.sign-up-link {
    color: var(--primary-color);
}

.container.active .sign-in {
    transform: translateX(100%);
}

.sign-up {
    left: 0;
    width: 50%;
    opacity: 0;
}

.container.active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move {

    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.social-icons {
    margin: 5px 0;
    cursor: not-allowed;
}

.social-icons a {
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggle-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 20;
}

.container.active .toggle-container {
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle {
    background-color: #0093fb;
    height: 100%;
    background: linear-gradient(to right, #5c6bc0, #0093fb);
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.container.active .toggle {
    transform: translateX(50%);
}

.toggle-panel {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left {
    transform: translateX(-200%);
}

.container.active .toggle-left {
    transform: translateX(0);
}

.toggle-right {
    right: 0;
    transform: translateX(0);
}

.container.active .toggle-right {
    transform: translateX(200%);
}

.textfieldCheck {
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.small-placeholder .MuiInputBase-input::placeholder {
    font-size: 0.5rem !important;
    /* Adjust the size as needed */
}

.login-container {
    height: 97vh;
}

.blue-background {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.co-lending-container {
    text-align: center;
}

.co-lending-text {
    font-size: 28px;
    font-weight: 600 !important;

}

.tagline-text {
    font-size: 16px;
    margin-top: 10px;
    font-style: italic;
}

.login-form {
    display: flex;
    width: 50% !important;
    align-items: center;
    padding: 20px;
}

.login-fields {
    width: 60%;
}

.logo {
    /* Adjust the size as needed */
    margin-bottom: 20px;
}

.login-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.form-grid {
    display: grid;
    grid-gap: 10px;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    /* Add this line to center align */
}

@media screen and (max-width: 480px) {
    .form-grid {
        display: grid;
        grid-gap: 10px;
        width: 100%;
        max-width: 80%;
        margin: 0 auto;
        /* Add this line to center align */
    }
}

.input {
    width: 100%;
}

.links-spacing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.forgot-password {
    flex: 1; 
    text-align: left;
}

.login-other-acc {
    flex: 1; 
    text-align: right;
}

.forgot-password-link {
    display: block; 
}

.login-other-acc-link {
    display: block; 
}

@media screen and (min-width: 768px) {
    .forgot-password {
        margin-top: 10px;
        text-align: right;
        /* Align to the right */
    }
}

@media screen and (min-width: 768px) {
    .login-other-acc {
        margin-top: 10px;
        text-align: left;
        /* Align to the right */
    }
}

.forgot-password-link {
    color: var(--primary-color);
}

.sign-up-link-text {
    color: var(--primary-color);
}

.enquiry-link {
    margin-top: 10px;
    text-align: right;
}

.enquiry-link-text {
    color: var(--primary-color);
    justify-content: end;
}

.disabled-icon {
    color: #ccc;
    /* Color for disabled state */
    cursor: not-allowed !important;
    /* Show a "not-allowed" cursor */
    pointer-events: none;
    /* Disable all mouse events */
}

.disabled-icon a {
    pointer-events: none;
    /* Disable all mouse events for links */
    color: #ccc;
    /* Ensure link color is the same as icon color */
}

/* ====================================== */

.verification-container {
    height: 97vh;
}

.blue-background {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.co-lending-container {
    text-align: center;
}

.co-lending-text {
    font-size: 28px;
    font-weight: 600 !important;
    
}

.tagline-text {
    font-size: 16px;
    margin-top: 10px;
    font-style: italic;
}

.verification-form {
    display: flex;
    width: 50% !important;
    align-items: center;
    padding: 20px;
}

.verification-fields {
    width: 60%;
}

.signup-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.form-grid {
    display: grid;
    grid-gap: 10px;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    /* Add this line to center align */
}
@media screen and (max-width: 480px) {
    .form-grid {
        display: grid;
        grid-gap: 10px;
        width: 100%;
        max-width: 80%;
        margin: 0 auto;
    }
}

.generate-button-container{
    display: flex;
    justify-content: center;
}

.generate-button{
    text-transform: none;
    background-color: var(--primary-color);
    margin-top: auto; 
}

.verify-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.verify-button {
    text-transform: none;
    background-color: var(--primary-color);
    margin-top: auto; 
}

.otp-feild{
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-input-feild{
  width: 200px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  margin-top: 3%;
}

.otp-input-feild:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
  }

  .otp-input-feild::placeholder {
    color: #0093fb; 
  }
  
  
.resend-button{
    width: fit-content;
}