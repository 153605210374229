.customer-details-box {
  /* margin-bottom: 1%; */
  border: none;
  border-radius: 10px;
  padding: 3%;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 35vh !important;
  height: 35vh !important;
  max-height: 40vh !important;

}

.loan-details-box {
  /* margin-bottom: 1%; */
  border: none;
  border-radius: 10px;
  padding: 3%;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-height: 37.2vh !important;
      height: 37.2vh !important;
        max-height: 40vh !important;

}

.mob{
    text-align: right;
}