.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 2.5vh;
    /* Set your desired background color */
}

.logo {
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-weight: 700;
}

.logo img {

    width: 30px;
    /* Set your desired logo width */
    margin-right: 10px;
}

.options {
    display: flex;
}

.options span {
    margin-left: 20px;
    /* Set your desired spacing between options */
    cursor: pointer;
}
.logoHeading{
    font-size: 1.5rem;
}
.menuItems{
    font-size: 1rem;
    color: var(--primary-color);
    font-weight: 700;
}
.headerLine {
    width: 100%;
    background-color: #ccc;
}