.footer{
    margin: 10px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #797979;
    padding: 15px 0;
}
.footer ul li{
    list-style: none;
    display: inline-block;
    margin-left: 20px;
}
/* ------ media query ---------- */
@media (max-width: 600px){
    .footer{
        display: block;
        text-align: center;
    }
    .footer ul li{
        margin: 10px;
    }
    .footer ul{
        margin-top: 10px;
    }
}