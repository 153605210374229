.ModelClass {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: "var(--white-color)";
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 24px;
}

.disbursementDetailsPopup {
    height: 40vh !important;
}

.grey-background {
    background-color: var(--color);
    text-align: left;
    display: flex;
    padding: "20px";
    border-radius: 20px;
    box-shadow: inset;
}

.blue-text {
    color: var(--primary-color);
    cursor: pointer;
}

.blue-text-no-cursor {
    color: var(--primary-color);
}

.details-box {
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1%;
    border: none;
    border-radius: 10px;
    padding: 1%;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}