.signup-container {
    height: 97vh;
}

.blue-background {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.co-lending-container {
    text-align: center;
}

.co-lending-text {
    font-size: 28px;
    font-weight: 600 !important;
    
}

.tagline-text {
    font-size: 16px;
    margin-top: 10px;
    font-style: italic;
}

.signup-form {
    display: flex;
    width: 50% !important;
    align-items: center;
    padding: 20px;
}

.signup-fields {
    width: 60%;
}

.logo {
    width: 80px; /* Adjust the size as needed */
    margin-bottom: 20px;
}

.signup-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.form-grid {
    display: grid;
    grid-gap: 10px;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    /* Add this line to center align */
}
@media screen and (max-width: 480px) {
    .form-grid {
        display: grid;
        grid-gap: 10px;
        width: 100%;
        max-width: 80%;
        margin: 0 auto;
        /* Add this line to center align */
    }
}
.input {
    width: 100%;
}

.login {
    margin-top: 10px;
    text-align: right; /* Align to the right */
}

.login-link {
    color: var(--primary-color);
}

.signup-button {
    margin-top: 20px;
}