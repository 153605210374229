
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 80vh;
    align-items: center;

}

.grid-item:hover {
    background-color: var(--primary-color);
    color: white;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transform: scaleY(1.1);

    /* background-color: rgba( 255, 255, 255, 0.45 ); */
    /* background-color: var(--primary-color);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19px );
    -webkit-backdrop-filter: blur( 19px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center align items horizontally */
    justify-content: center;
    /* Center align items vertically */
    background-color: #f0f0f0;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    height: 30vh;
    transition: transform 0.3s ease;
}

.icon {
    margin-bottom: 10px;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center align items horizontally */
    margin-top: 10px;
    /* Adjust as needed for spacing */
}

.title {
    font-size: 1.5rem;
}

.description {
    font-size: 1rem;
    text-align: center;
    /* Center align the text within the description */
}

.grid-container-custom {
    width: 80vw;
    margin: auto;
}

.user-details {
    text-align: left;
    /* margin-top: 2px; */
    display: flex;
    padding: "20px";
    border-radius: 20px;
    box-shadow: inset;
}

.details-box {
    margin-left: auto;
    border: none;
    border-radius: 10px;
    padding: 1%;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: grid;
}

.reloadreqtext {
    color: var(--primary-color);
}

.grid-container-element {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center align items horizontally */
    justify-content: left;
    /* Center align items vertically */
}

.amount-textfeild {
    display: inline-block;
    width: 35%;
}
