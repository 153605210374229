

.about{
    width: 80%;
    margin: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-left{
    flex-basis: 40%;
    position: relative;
}
.about-right{
    flex-basis: 56%;
}
.about-img{
    width: 100%;
    border-radius: 10px;
}
.play-icon{
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}
.about h3{
    font-weight: 600;
    font-weight: 16px;
    color: #0093fb;
}
.about h2{
   font-size: 35px;
   color: #000F38;
   margin: 10px 0;
   max-width: 400px;
}
.about p{
    color: #676767;
    margin-bottom: 15px;
}
/* ------- media query ------------- */
@media (max-width: 800px){
    .about{
        flex-direction: column;
    }
    .about-left{
        flex-basis: 100%;
        margin: 20px;
        order: 2;
    }
    .about-right{
        flex-basis: 100%;
    }
}