/* .hero {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 147, 251, 0.4), rgba(0, 147, 251, 0.3)),
    url("../../../Assets/hero.png");
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-text {
  text-align: center;
  max-width: 800px;
}
.hero-text h1 {
  font-size: 60px;
  font-weight: 600;
}
.hero-text p {
  max-width: 700px;
  margin: 10px auto 20px;
  line-height: 1.4;
}
/* ---------- media query --------- */

/* @media (max-width: 850px) {
  .hero-text h1 {
    font-size: 40px;
  }
}
@media (max-width: 650px) {
  .hero-text h1 {
    font-size: 30px;
    max-width: 400px;
    margin: auto;
  }
  .hero-text p {
    font-size: 14px;
    margin: 15px auto 30px;
  }
}  */


.hero {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0,
              147,
              251, 0.4), rgba(0,
              147,
              251, 0.3)), url("../../../Assets/hero.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;  
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-text {
  text-align: center;
  max-width: 800px;
}

.hero-text h1 {
  font-size: 50px;
  font-weight: 600;
}

.hero-text p {
  max-width: 700px;
  margin: 10px auto 20px;
  line-height: 1.4;
}

/* ---------- media query --------- */

@media (max-width: 850px) {
  .hero-text h1 {
      font-size: 40px;
  }
}

@media (max-width: 650px) {
  .hero-text h1 {
      font-size: 30px;
      max-width: 400px;
      margin: auto;
  }

  .hero-text p {
      font-size: 14px;
      margin: 15px auto 30px;
  }
}

.hero-container {
  position: relative;
  display: flex;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  /* Adjust the width of the background image as needed */
  height: 100%;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text {
  padding: 20px;
}

.btn {
  background: #FFF;
  color: #212121;
  padding: 14px 25px;
  font-size: 14px !important;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
