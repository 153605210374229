.campus{
    margin: 80px auto;
    width: 90%;
    text-align: center;
}
.gallery{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    
}
.gallery img{
    width: 23%;
    border-radius: 10px;
}
/* ------- media query ------------- */
@media (max-width: 800px){
    .gallery{
        flex-wrap: wrap;
    }
   .gallery img{
        width: 48%;
        margin-bottom: 25px;
   }
}
.hover-container:hover .hover-effect {
    opacity: 1;
}

.hover-text {
    display: none;
}

.hover-container:hover .hover-text {
    display: block;
}