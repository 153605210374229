.blue_background{
    background-color: var(--pending-bgcolor);
    justify-content: flex-start;
}

.text-color{
    color: var(--primary-color);
    font-size: 20em;
}

.error-icon-container{
    align-items: center;
}

.error-text{
   font-size: 200px;
   /* align-items: center;
   text-align: center; */
}

.img{
    /* margin: 10%; */
    height:100vh;
    width:100vw;
    fill:white;
}

.display-linebreak {
    white-space: pre-line;
  }