.loadingbackground {
  background-color: #fff;
  opacity: 0.8;
  /* left: 20vw; */
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.loader {
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spinloader 2s linear infinite;
  /* position: absolute;
  top: 30%;
  left: 40%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.loader img {
  height: 200px;
  width: 200px;
  animation: spinlogo 2s linear infinite;
  padding: 10%;
}
@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.loading-text {
  text-align: center;
}

.dot-flashing {
  margin-left: 15px;
  display: inline-block;
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #000000
  }
  50%, 100% {
    background-color: #c9c9c9
  }
}