.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --primary-color: #0093fb;
  /* --success-color:#1EED7D;
  --fail-color: red;
  --pending-bgcolor: rgb(204, 235, 241);
  --success-bgcolor: rgba(204, 241, 214, 1);
  --fail-bgcolor: rgba(255, 59, 59, 0.2);
  --background-color: #f0f0f0; */
  --white-color:#ffffff;
  --pending-bgcolor: rgb(204, 235, 241);
  --success-color: rgba(0, 186, 52, 1);
  --success-bgcolor: rgba(204, 241, 214, 1);
  --fail-color: rgba(233, 44, 44, 1);
  --fail-bgcolor: rgba(255, 59, 59, 0.2);
  --suspect-color: #FFA500;
  --suspect-bgcolor:#ffe8bc;
  --background-color: #f0f0f0;
  --styled-tree-text-color:#1a73e8;
  --styled-tree-bg-color:#e8f0fe;
  --darkmode-styled-tree-text-color:#B8E7FB;
  --darkmode-styled-tree-bg-color:#071318;


  /* Define your color here */
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  width: 100%;
  text-align: left;
  justify-content: left !important;
  padding: 2% !important;
}
/* ::-webkit-scrollbar {
  width: 7px;
    height: 7px;
} */

/* Track
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
} */

/* Handle
::-webkit-scrollbar-thumb {
  background: rgb(148, 147, 147);
  border-radius: 10px;
} */

/* Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #767575;
} */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 147, 251, 0.7);
  /* 50% opacity */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 147, 251, 1);
  /* 70% opacity */
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

body {
  background: #F5F5F5;
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container {
  padding-left: 10%;
  padding-right: 10%;
}

.btn {
  background: #FFF;
  color: #212121;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn img {
  width: 20px;
  margin-left: 10px;
}

.btn.dark-btn {
  background: #0093fb;
  color: #fff;
}

/* ----- media query ----------- */

@media (max-width: 1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .btn {
    padding: 14px 22px;
  }
}
.breadcrumbsStyle {
  background-color: #0093fb;
  margin-top: 10px;
  position: relative;
  padding-left: 10px;
}

.box-index{
  height:18vh;
  background: rgb(11,117,191);
  background: linear-gradient(135deg, rgba(11,117,191,1) 15%, rgba(0,147,251,1) 50%, rgba(116,189,241,1) 100%);
}