.testimonials{
    margin: 80px;
    padding: 0 80px;
    position: relative;
}
.next-btn, .back-btn{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 15px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    background: #0093fb;
}
.back-btn{
    right: auto;
    left: 0;
}
.slider{
    overflow: hidden;
}
.slider ul{
    display: flex;
    width: 200%;
    overflow-x: hidden;
    transition: 0.5s;
}
.slider ul li{
    list-style: none;
    width: 50%;
    padding: 20px;
}
.slide{
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    padding: 40px;
    border-radius: 10px;
    color: #676767;
    line-height: 1.4;
}
.slide img{
    width: 65px;
    border-radius: 50%;
    margin-right: 10px;
    border: 4px solid #0093fb;
}
.user-info{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 15px;
}
.user-info h3{
    color: #0093fb;
}
.user-info img {
    height: 8vh;
}
/* ------- media query ------------- */
@media (max-width: 800px){
    .testimonials{
        padding: 0 40px;
    }
    .next-btn, .back-btn{
        padding: 10px;
        width: 35px;
    }
    .testimonials ul li{
        padding: 10px;
    }
    .slide{
        padding: 10px;
    }
    .slide img{
        width: 50px;
        border: 3px solid #0093fb;
    }
}
@media (max-width: 600px){
    .user-info{
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .user-info h3{
        font-size: 16px;
    }
    .slider p{
        font-size: 12px;
    }
}